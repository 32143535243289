import React from 'react';
// import { useAppTheme } from './GlobalAppTheme';
import Link from 'next/link';
import { motion } from 'framer-motion';
// import cx from 'classnames';

export default function StarLogo({ toggleOpen }) {
    // @refresh reset
    return (
        <motion.div
            animate={{
                opacity: 0.4
            }}
            initial={{
                opacity: 0
            }}
            transition={{
                duration: 0.5
            }}
            className="logo">
            <Link onKeyDown={() => toggleOpen()} role="presentation" onClick={() => toggleOpen()} title="Home - Jews for Jesus" hrefLang="en" href="/" as="/" passHref>
                <svg
                    className="mt-2.5 mb-3 w-14 dark:fill-white"
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 35.3 41.4"
                    // xml:space="preserve"
                >
                    <g transform="translate(0.000000,100.000000) scale(0.010000,-0.010000)">
                        <path
                            d="M1748.1,9875.2c-7.7-13-138.2-237.6-290.2-498.6l-276.3-474.8l-563.4-1.3c-456.3-0.9-563-2.2-561.2-6.8
        c1.3-3.2,126.9-220.5,279.9-482.4C489.4,8149,614.5,7933.5,615,7932.1c0.5-1.8-125.5-220-280.4-486
        C179.8,7180.2,52.9,6960.1,52,6957c-1.3-5,101.2-5.9,567-5.9l568.4-0.4l287.5-494.6c157.9-272.2,288.4-495,290.2-495
        c1.3,0.5,132.3,223.2,290.2,495l287.6,495l564.3,0.9l563.8,1.4l-280.8,485.6L2910,7924.5l39.1,68
        c22.1,37.3,149.4,256.5,283.1,486.4l243.9,418.5l-565.7,2.2l-565.6,2.2l-287.1,497.2c-158.4,273.6-289.3,497.7-292,498.6
        C1763.4,9898.6,1755.3,9888.3,1748.1,9875.2z M2622.9,8409.1l279.9-483.8l-11.7-20.7c-6.3-11.2-133.2-229.1-281.7-484.7l-270-463.9
        l-574.2-0.5h-574.7l-282.1,485.1c-155.2,266.4-282.6,486.9-282.6,489.1c0,3.6,525.6,911.2,553,955.3l6.8,9.9l578.7-0.9l578.7-1.3
        L2622.9,8409.1z"
                        />
                    </g>
                </svg>
            </Link>
        </motion.div>
    );
}
